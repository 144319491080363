import { Component, Vue } from 'vue-property-decorator'
import FormInsurancePayment from '@/components/InsuranceForm/InsurancePayment/InsurancePayment.vue'
import InsurancePayment from '@/Models/InsurancePayment'
import Insurance from '@/Models/Insurance'
import Crm from '@/Models/Crm'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import http from '@/services/http'
import router from '@/router'
import * as Helpers from '@/helpers'
import Header from '@/components/Header/Header.vue'
import menuModule from '@/store/menu'
import Animation from '@/components/Animation/index.vue'
@Component({
  components: {
    FormInsurancePayment,
    ModalDelete,
    Header,
    Animation,
  },
})
export default class ViewInsurancePayment extends Vue {
  showList = true
  data: Array<InsurancePayment> = []
  objInsurancePayment: InsurancePayment = new InsurancePayment()
  isAction = 'add'
  loading = false
  delete_modal_active = false
  member: Crm = new Crm()
  insurance: Insurance = new Insurance()
  currentPage = 1
  dataForm = {}
  header: Array<any> = [
    {
      field: 'identification',
      label: 'Identification',
    },
    {
      field: 'member_number',
      label: '# miembro',
    },
    {
      field: 'insurance',
      label: 'Id seguro',
    },
    {
      field: 'amount',
      label: 'Monto',
    },
    {
      field: 'periodFromRead',
      label: 'Periodo desde',
    },
    {
      field: 'periodUpToRead',
      label: 'Periodo hasta',
    },
  ]
  mounted() {
    this.getInsurancePayment()
    ;(this.$refs.header as any).isViewPrincipal = false
  }
  async getInsurancePayment() {
    this.loading = true
    try {
      const id = menuModule.id_param
      if (id == null) throw router.push({ name: 'CCSS' }).catch(console.log)
      const res = await http.get(
        `/api/insurance/arcr_insurance_payments/${id}/`,
      )
      if (!res.data) throw new Error(res.data)
      if (res.data.payments != 'Este miembro no tiene pagos de seguro') {
        this.data = res.data.payments.map((obj: any) => ({
          ...obj,
          member_number: obj.member_number.member_number,
          period_from: Helpers.dateParse(obj.period_from),
          period_up_to: Helpers.dateParse(obj.period_up_to),
          periodFromRead: obj.period_from,
          periodUpToRead: obj.period_up_to,
          insurance: obj.insurance.id,
          method_payment: obj.method_payment.id,
          date: Helpers.dateParse(obj.date),
        }))
      }
      this.dataForm = {
        member_number: res.data.member.member_number,
        identification: res.data.insurance.identification,
        insurance: id,
        coin_type: res.data.insurance.coin_type,
        amount: res.data.insurance.premium_amount,
        date: new Date(),
      }
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
    this.loading = false
  }
  editObj(props: any) {
    this.objInsurancePayment = props
    this.showList = false
    this.isAction = 'edit'
  }
  async deleteObj() {
    try {
      await http.delete(
        `/api/insurance/arcr_insurance_payments/${this.objInsurancePayment.id}/`,
      )
      this.data = this.data.filter(
        (a: InsurancePayment) => a.id !== this.objInsurancePayment.id,
      )
      this.delete_modal_active = false
      this.getInsurancePayment()
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }
  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objInsurancePayment = props
  }
  closeModal() {
    this.objInsurancePayment = new InsurancePayment()
    this.delete_modal_active = false
  }

  openForm() {
    this.showList = false
  }
  async cleanForm() {
    ;(this.$refs.header as any).showList = true
    this.objInsurancePayment = new InsurancePayment()
    this.showList = true
    this.isAction = 'add'
    await this.getInsurancePayment()
  }
  isData(data: any) {
    this.data = data
  }
}
